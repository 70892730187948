<template>
	<HeaderBar />

	<main>
		<router-view ref="content"></router-view>
		<Footer ref="footer"></Footer>
	</main>
</template>

<script setup>
	import HeaderBar from '@/components/layout/HeaderBar.vue';
	import Footer from '@/components/layout/FooterBar.vue';
	import { watch } from 'vue';
	import { useRoute } from 'vue-router';
	import { useI18n } from 'vue-i18n';
	import { onMounted } from 'vue';

	const route = useRoute();
	const i18n = useI18n();
	watch(
		() => route.fullPath,
		() => {
			if (route.query.lang) {
				i18n.locale.value = route.query.lang;
			}
		}
	);

	function adjustFooterPosition() {
		const windowHeight = window.innerHeight;
		const bodyHeight = document.body.offsetHeight;
		const footer = document.querySelector('.footer');
		const footerHeight = footer.offsetHeight;

		if (bodyHeight < windowHeight) {
			footer.style.marginTop = windowHeight - bodyHeight - footerHeight + 'px';
			footer.style.position = 'fixed';
			footer.style.width = '95vw';
			footer.style.maxWidth = '1134.4px';
			footer.style.left = '50%';
			footer.style.transform = 'translateX(-50%)';
		} else {
			footer.style.marginTop = '';
			footer.style.position = '';
			footer.style.width = '';
			footer.style.maxWidth = '';
			footer.style.left = '';
			footer.style.transform = '';
		}
	}

	onMounted(() => {
		window.onload = () => {
			setTimeout(() => {
				adjustFooterPosition();
			}, 500);
		};
	});
</script>
