import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

const delay = (t) => new Promise((r) => setTimeout(r, t));

const router = createRouter({
	history: createWebHistory(),
	routes,
	async scrollBehavior(to, from, savedPosition) {
		await delay(0);
		return { top: 0 };
	},
});

function adjustFooterPosition() {
	const windowHeight = window.innerHeight;
	const bodyHeight = document.body.offsetHeight;
	const footer = document.querySelector('.footer');
	const footerHeight = footer.offsetHeight;

	if (bodyHeight < windowHeight) {
		footer.style.marginTop = windowHeight - bodyHeight - footerHeight + 'px';
		footer.style.position = 'fixed';
		footer.style.width = '95vw';
		footer.style.maxWidth = '1134.4px';
		footer.style.left = '50%';
		footer.style.transform = 'translateX(-50%)';
	} else {
		footer.style.marginTop = '';
		footer.style.position = '';
		footer.style.width = '';
		footer.style.maxWidth = '';
		footer.style.left = '';
		footer.style.transform = '';
	}
}

router.beforeEach((to, from, next) => {
	adjustFooterPosition(); 
	next();
});

window.addEventListener('popstate', () => {
	adjustFooterPosition(); 
  });

export default router;
