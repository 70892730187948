<template>
	<div class="content-width-md">
		<div class="footer">
			<div class="footer__top">
				<div class="footer__claim">{{ $t('footer.claim') }}</div>
				<div class="footer__share">
					<a target="_blank" href="https://www.facebook.com/boschbuildingsolutions/">
						<i class="icn icn--facebook"></i>
					</a>
					<a target="_blank" href="https://www.youtube.com/channel/UC2gMLnn7iZbOyMKDzyLbbsg">
						<i class="icn icn--youtube"></i>
					</a>
					<a target="_blank" href="https://www.linkedin.com/company/boschbuildingsolutions/">
						<i class="icn icn--linkedin"></i>
					</a>
				</div>
			</div>
			<div class="divider"></div>
			<div class="footer__bottom">
				<a href="#"><i class="icn icn--up"></i></a>
				<ul class="footer__links">
					<li v-for="(link, key) in footerLinks" :key="key">
						<RouterLink v-if="link.type == 'internal'" :to="{ path: link.url }">{{
							link.title
						}}</RouterLink>
						<a v-else :href="link.url">{{ link.title }}</a>
					</li>
				</ul>
				<p>{{ $t('footer.company') }}</p>
			</div>
		</div>
	</div>
</template>
<script setup>
	import { useI18n } from 'vue-i18n';
	import { computed } from 'vue';
	const { locale, messages } = useI18n();

	const footerLinks = computed(() => {
		return messages.value[locale.value].footerLinks;
	});
</script>
