import './scss/main.scss';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from './router/router.js';
import App from './App.vue';
import i18n from './i18n.js';

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(i18n);

app.mount('#app');
