<template>
	<div class="supergraphic"></div>
	<header class="header-bar">
		<div class="content-width-md">
			<div class="header-bar__nav-bar">
				<RouterLink to="/"> <img class="header-bar__logo" src="@/assets/Bosch_symbol_logo_black_red.svg" /></RouterLink>

				<nav class="header-bar__nav">
					<a :href="$t('headerBar.aboutUsLink')" target="_blank">{{ $t('headerBar.aboutUs') }}</a>
					<div @click="changeLocale" class="header-bar__language-switch"></div>
				</nav>
			</div>
			<div class="header-bar__title-bar">{{ $t('headerBar.title') }}</div>
		</div>
	</header>
</template>
<script setup>
	import { useI18n } from 'vue-i18n';

	const { locale } = useI18n();

	function changeLocale() {
		if (locale.value == 'de') {
			locale.value = 'en';
		} else {
			locale.value = 'de';
		}
	}
</script>
<style scoped lang="scss">
	@use '@/scss/functions/breakpoints';

	.header-bar__nav-bar {
		height: 3rem;
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: center;
		width: 100%;

		@include breakpoints.breakpoint-up('lg') {
			height: 6rem;
		}
	}

	.header-bar__nav {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 100%;
	}

	.header-bar__nav a {
		text-decoration: none;
		color: var(--bs-body-color);
		padding: 0.75rem 1rem;

		&:hover {
			color: var(--bs-blue);
		}
	}

	.header-bar__logo {
		display: block;
		width: auto;
		max-width: 9rem;
		height: 4rem;

		@include breakpoints.breakpoint-up('lg') {
			height: 4.75rem;
		}
	}

	.header-bar__title-bar {
		box-sizing: border-box;
		padding-block: 0.75rem;
		font-weight: 700;
		text-align: right;
	}

	.header-bar__language-switch {
		padding: 0.75rem;
		cursor: pointer;

		&::before {
			content: '';
			display: block;
			height: 1.75rem;
			width: 1.75rem;
			background-color: var(--bs-body-color);
			mask-size: contain;
			mask-position: center;
			mask-repeat: no-repeat;
			mask-image: url('@/assets/icons/globe.svg');
		}

		&:hover::before {
			background-color: var(--bs-blue);
		}
	}
</style>
